<template>
  <!-- If don't saved -->
  <ModalEmpty
    v-if="isNoSaved"
    @close="
      () => {
        isNoSaved = false
        leaveRoute = ''
      }
    "
  >
    <div class="w-500 p-30 flex ai-c jc-c fd-c ta-center">
      <h1 class="title">Не сохранили изменения</h1>
      <div class="t-grey-dark fs-16 mt-5 mb-30">
        Ваши изменения будут утерянны если их не сохранить, все равно хотите уйти?
      </div>

      <div class="grid ggap-10 w-100">
        <div class="grid gtc-2 ai-c ggap-10">
          <button class="btn grey jc-c" :disabled="!isNoData" @click="submitAction(true)">
            <BaseIcon class="ic-16 primary" icon="doc-2" />
            Сохранить в черновик
          </button>
          <button class="btn primary jc-c" :disabled="!isNoData" @click="submitAction(false)">
            <BaseIcon class="ic-16 white" icon="check-circle" />
            Отправить
          </button>
        </div>
        <button class="btn transparent-grey jc-c" @click="$router.push(leaveRoute)">
          <BaseIcon class="ic-16 red" icon="close-circle" />
          Не сохранять и уйти
        </button>
      </div>
    </div>
  </ModalEmpty>

  <ModalWrapper @close="closeAction">
    <!-- Head -->
    <template #head>
      <div class="flex ai-c ggap-10">
        <BaseIcon class="ic-20 primary" icon="edit" />
        <h1 class="title">Редактирование заявки {{ item?.id && '№' + item?.id }}</h1>
      </div>
    </template>

    <!-- Body -->
    <template #default>
      <!-- <div class="form-add grid gtc-1-auto"> -->
      <form class="form-add pb-40 pos-r z0" id="form-add">
        <BaseLoad v-if="isLoad" class="grid jc-c z99999 bg white" />
        <!-- Основные данные -->
        <section class="form-add__section grid ggap-20">
          <BaseAlert type="yellow">
            Пожалуйста, пишите максимально полно и понятно, за что осуществляется оплата. Это избавит нас от
            необходимости задавать дополнительные вопросы и ускорит процесс.
            <br /><br />
            Если вы хотите взять наличные деньги или если после оплаты не будут предоставляться закрывающие документы
            (АВР, ЭСФ, чеки и накладные), то напишите об этом в комментарии.
            <br /><br />
            По вопросам закрывающих документов обращайтесь в бухгалтерию. По остальным вопросам – в бухгалтерию или к
            финансистам.
          </BaseAlert>
          <div class="flex ai-c ggap-10">
            <h2 class="title">Основные данные</h2>
          </div>
          <div class="grid gtc-2 ggap-20 ai-fs">
            <FormAutocomplete
              label="Тип оплаты"
              placeholder="-"
              required
              :options="optionsPayType"
              :disabled="item?.status?.id === 21"
              v-model="formData.type"
              :isSearch="false"
            />
            <FormAutocomplete
              label="Проект"
              required
              :disabled="item?.status?.id === 21"
              placeholder="-"
              :options="optionsProjects"
              v-model="formData.company"
            />
          </div>

          <!-- КОНТРАГЕНТ -->
          <div
            class="form-add__section-box grid ggap-20"
            v-if="['counterparty', 'gpc_agreement', 'holding_loans', 'regular_payments'].includes(formData.type.value)"
          >
            <hr />
            <div class="flex ai-c jc-sb">
              <h3 class="m-0">Контрагент</h3>
              <button
                type="button"
                @click.prevent.stop="isNewCounterparty = !isNewCounterparty"
                class="btn transparent-grey sm pl-10"
              >
                <FormFakeSwitch :isTrue="isNewCounterparty" />
                Добавить нового
              </button>
            </div>

            <div v-if="isNewCounterparty" class="grid gtc-2 ggap-20 ai-fs">
              <FormInput
                label="БИН / ИИН"
                placeholder="Введите БИН или ИИН"
                v-model="formData.counterparty_bin"
                :maxlength="12"
                :minlength="12"
                type="number"
                required
              />
              <FormInput
                label="Юр. наименование контрагента"
                placeholder="Введите наименование контрагента"
                v-model="formData.counterparty_company_name"
                required
              />
            </div>
            <div v-else class="grid gtc-2 ggap-20 ai-fs">
              <FormAutocompleteCounterparty
                class="f-1"
                :label="formData.type.value === 'gpc_agreement' ? 'ИИН' : 'БИН / ИИН'"
                placeholder="Выберите"
                :options="optionsCounterparties"
                :isLoad="isCounterpartyLoad"
                :isMultiple="false"
                required
                @search="searchCounterparties($event)"
                v-model="formData.counterparty"
              >
                <template #single="{ item }">
                  <div v-if="item && item.id" class="grid gtc-auto-1 ggap-10">
                    <div class="grid gtc-auto-1 ai-c ggap-10">
                      <div class="cut">{{ item.text }}</div>
                    </div>
                  </div>
                  <div v-else class="t-grey-dark">-</div>
                </template>
              </FormAutocompleteCounterparty>
              <ItemRead label="Юр. наименование контрагента">
                <div
                  class="flex ai-c pt-10 fw-b"
                  :class="{ 't-grey-dark': !(formData.counterparty && formData.counterparty.name) }"
                >
                  {{
                    formData.counterparty && formData.counterparty.name
                      ? formData.counterparty.name
                      : 'Нет наименования'
                  }}
                </div>
              </ItemRead>
            </div>
            <div class="grid gtc-2 ggap-20 ai-fs" v-if="formData.counterparty?.id || isNewCounterparty">
              <FormAutocomplete label="КБЕ" required :options="kbeOptions" v-model="formData.counterparty_kbe" />
              <FormAutocomplete label="КНП" required full :options="knpOptions" v-model="formData.counterparty_knp" />
            </div>
            <div class="flex ai-c jc-sb" v-if="formData.counterparty?.id || isNewCounterparty">
              <h3 class="m-0">Счета</h3>
              <button
                v-if="!isNewCounterparty"
                type="button"
                @click.prevent.stop="isNewAccount = !isNewAccount"
                class="btn transparent-grey sm pl-10"
              >
                <FormFakeSwitch :isTrue="isNewAccount" />
                Добавить новый счёт
              </button>
            </div>
            <div class="grid gtc-1 ggap-20 ai-fs" v-if="formData.counterparty?.id || isNewCounterparty">
              <FormInput
                label="Номер расчетного счёта (ИИК)"
                v-if="isNewAccount || isNewCounterparty"
                placeholder="-"
                v-model="formData.counterparty_iban"
                :maxlength="ibanLength"
                required
              />
              <FormAutocomplete
                label="Номер расчетного счёта (ИИК)"
                required
                v-if="!isNewAccount && !isNewCounterparty"
                :options="optionsAccounts"
                v-model="formData.counterparty_account"
              />
            </div>
            <div class="grid gtc-2 ggap-20 ai-fs" v-if="formData.counterparty?.id || isNewCounterparty">
              <FormAutocomplete
                label="Банк"
                required
                placeholder="-"
                :options="optionsBanks"
                v-if="isNewAccount || isNewCounterparty"
                :disabled="!isNewAccount && !isNewCounterparty"
                v-model="formData.counterparty_bank_id"
              />
              <FormInput
                label="Банк"
                placeholder="-"
                type="text"
                v-if="!isNewAccount && !isNewCounterparty"
                :disabled="!isNewAccount && !isNewCounterparty"
                v-model="formData.counterparty_bank_name"
                required
              />
              <FormInput
                label="БИК"
                placeholder="-"
                type="number"
                onlyLatin="true"
                disabled
                v-model="formData.counterparty_bik"
                :maxlength="8"
                :minlength="8"
                required
              />
            </div>
            <div class="grid ggap-20 ai-fs" v-if="formData.counterparty?.id || isNewCounterparty">
              <FormAutocomplete
                label="Основания для оплаты"
                required
                placeholder="-"
                :options="paymentTypeOptions"
                v-model="formData.payment_ground_type"
              />
            </div>
            <div
              class="grid ggap-20 gtc-2 ai-fs"
              v-if="['payment_invoice', 'bill_invoice'].includes(formData.payment_ground_type?.value)"
            >
              <FormInput
                label="Счёт на оплату"
                placeholder="Номер счёта"
                v-model="formData.payment_ground_number"
                required
              />
              <FormDate
                v-model="formData.payment_ground_date"
                type="date"
                required
                format="YYYY-MM-DD"
                label="Дата счёта"
                showPastDays
                placeholder="-"
              />
            </div>
            <div class="grid ggap-20 gtc-1 ai-fs" v-if="formData.counterparty?.id || isNewCounterparty">
              <FormAutocomplete
                label="Наличие договора"
                required
                placeholder="-"
                :options="contractExistsOptions"
                v-model="formData.contract_number"
              />
            </div>
            <div class="grid ggap-20 gtc-1 ai-fs" v-if="formData.contract_number?.value === 'custom'">
              <FormInput label="Номер договора" placeholder="-" v-model="formData.new_contract_number" required />
              <FormDate
                v-model="formData.new_contract_date"
                v-if="formData.contract_number?.value === 'custom'"
                type="date"
                required
                format="YYYY-MM-DD"
                label="Дата договора"
                showPastDays
                placeholder="-"
              />
            </div>
            <div class="grid ggap-20 gtc-1 ai-fs" v-if="formData.payment_ground_type?.value === 'other'">
              <FormInput
                label="Назначение платежа (другое)"
                placeholder=""
                v-model="formData.payment_ground_purpose"
                required
              />
            </div>
            <hr />
          </div>
          <!-- КОНТРАГЕНТ -->

          <div class="grid gtc-2 ggap-20 ai-fs">
            <FormInput
              class="sm w-100"
              label="За что платим"
              placeholder="-"
              v-model="formData.pay_for"
              @keydown.enter.prevent
              required
            />
            <FormSumm
              class="sm w-100"
              label="Сумма"
              placeholder="-"
              :disabled="item?.status?.id === 21"
              separator="true"
              type="number"
              v-model="formData.cost"
              v-model:currencyValue="formData.currency"
              @keydown.enter.prevent
              required
            />
          </div>
          <FormTextarea
            label="Комментарий"
            placeholder="Особые условия (например: условия оплаты, ответственность и т.д.)"
            class="big"
            v-model="formData.commentary"
          />
          <BaseAlert type="yellow" v-if="formData?.type?.value === 'prepayment'">
            Политика выдачи авансов ChocoFamily <br /><br />
            <div class="mb-5">
              1) Авансы выдаются сотрудникам только в случае наступления каких-либо неожиданных (форс-мажорных)
              обстоятельств, которые другим способом у сотрудника разрешить не получается;
            </div>
            <div class="mb-5">2) Размер аванса не должен превышать 30% от суммы заработной платы «на руки»;</div>
            <div class="mb-5">
              3) Аванс выдаётся сроком только до ближайшей зарплаты и будет полностью погашен из нее. Рассрочка в
              погашении аванса не предусмотрена.
            </div>
            <div class="mb-5">
              4) Выдача аванса должна быть одобрена непосредственным или вышестоящим руководителем сотрудника, при этом
              сотрудник должен пояснить руководителю причину возникновения необходимости в авансе. К заявке необходимо
              приложить скриншот переписки с руководителем, из которой четко понятно, что руководитель одобрил получение
              аванса.
            </div>
            <div class="mb-5">
              5) В исключительных случаях размер и срок аванса может быть пересмотрен, но только по согласованию с
              Директором проекта и Финансовым директором Холдинга.
            </div>
          </BaseAlert>
        </section>

        <!-- Файлы -->
        <section class="form-add__section grid ggap-20">
          <div class="flex ai-c ggap-10">
            <h2 class="title">Файлы заявки</h2>
            <BaseIcon
              class="form-item__help ic-16 grey"
              icon="alert"
              v-tippy="'Прикрепите те файлы которые будут участвовать в создании или рассмотрении документа'"
            />
          </div>
          <FormFile v-model="formData.documents" :maxSize="2" />
        </section>

        <!-- Данные автора -->
        <section class="form-add__section grid ggap-20">
          <h2 class="title">Ваши данные</h2>
          <div class="flex ai-c ggap-10 fw">
            <span
              v-for="contact of contacts"
              :key="contact.id"
              class="box pt-5 pb-5 pl-10 pr-10 fs-14 flex ai-c ggap-5"
              v-tippy="'Ваш личный контакт из вашего профиля'"
            >
              <BaseIcon class="ic-18 grey" :icon="contact.type" />
              {{ contact.value }}
            </span>
            <span
              v-for="(contact, index) in formData.newContactList"
              :key="contact.value"
              class="box pt-5 pb-5 pl-10 pr-5 fs-14 flex ai-c ggap-10"
              v-tippy="'Новый контакт'"
            >
              <div class="flex ai-c ggap-5">
                <BaseIcon class="ic-18 primary" :icon="contact.type" />
                {{ contact.value }}
              </div>
              <button class="btn xsm transparent-grey rounded cube-20" @click="removeContact(index)">
                <BaseIcon class="ic-14 red" icon="close" />
              </button>
            </span>
          </div>
        </section>
      </form>
    </template>

    <!-- Foot -->
    <template #foot>
      <div
        class="flex ai-c jc-sb ggap-20"
        :class="{ 'jc-fe': item?.status?.id === 21, 'jc-sb': item?.status?.id !== 21 }"
      >
        <button class="btn grey" :disabled="!isNoData" v-if="item?.status?.id !== 21" @click="submitAction(true)">
          <BaseIcon class="ic-16 primary" icon="doc-2" />
          Сохранить в черновики
        </button>
        <button class="btn primary" :disabled="!isNoData" @click="submitAction(false)">
          <BaseIcon class="ic-16 white" icon="check-circle" />
          Отправить заявку
        </button>
      </div>
    </template>
  </ModalWrapper>
</template>

<script setup>
import orders from '@/api/modules/orders'
import { cloneDeep } from 'lodash'
import { ref, reactive, watch, computed, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter, onBeforeRouteLeave, useRoute } from 'vue-router'
import { isInvalidForm, $busEmit, disableAllBtns } from '@/plugins'
import {
  ModalEmpty,
  ModalWrapper,
  BaseIcon,
  FormInput,
  FormAutocomplete,
  FormTextarea,
  FormFakeSwitch,
  FormAutocompleteCounterparty,
  FormFile,
  BaseLoad,
  FormSumm,
  ItemRead,
  BaseAlert,
  FormDate
} from '@/components'

// Data
const store = useStore()
const router = useRouter()
const route = useRoute()

// Form data
const formData = reactive({
  type: {},
  company: {},
  pay_for: '',
  cost: '',
  currency: 'kzt',
  counterparty: {},
  counterparty_id: '',
  counterparty_company_name: '',
  counterparty_bin: '',
  counterparty_kbe: '',
  counterparty_knp: '',
  counterparty_bik: '',
  counterparty_iban: '',
  counterparty_account: '',
  comments: '',
  contract_exists: false,
  contract_number: '',
  documents: [],
  bank: ''
})

const contractExistsOptions = computed(() => {
  let contracts = []
  if (formData?.counterparty?.contracts?.length) {
    formData?.counterparty?.contracts.map((el) => {
      contracts.push({ value: el.id, text: el.number + ' ' + el.date_to })
    })
  }
  return [
    {
      text: 'Ввести вручную',
      value: 'custom'
    },
    {
      text: 'Нет договора',
      value: 'none'
    },
    ...contracts
  ]
})

const paymentTypeOptions = [
  {
    text: 'Счет на оплату',
    value: 'payment_invoice'
  },
  {
    text: 'Счет фактура',
    value: 'bill_invoice'
  },
  {
    text: 'Договор',
    value: 'contract_number'
  },
  {
    text: 'Другое',
    value: 'other'
  }
]

const leaveRoute = ref('')
const isNoSaved = ref(false)
const isLoad = ref(false)
const isCounterpartyLoad = ref(false)
const isNewCounterparty = ref(false)
const isNewAccount = ref(false)
const copyDocs = ref({})

// Computed
const isNoData = computed(() => formData.type?.value && formData.project?.value)
const contacts = computed(() => store.getters?.profile?.contacts || [])
const optionsBanks = computed(() => store.getters.banks || [])
const optionsProjects = computed(() => store.getters.projects || [])
const optionsCounterparties = computed(() => {
  const arr = store.getters.counterparties || []
  arr.push({
    contracts: item.value?.counterparty?.contracts,
    id: item.value?.counterparty?.id,
    text: item.value?.counterparty?.bin + ' - ' + item.value?.counterparty?.company_name,
    value: item.value?.counterparty?.bin,
    name: item.value?.counterparty?.company_name,
    kbe: item.value?.counterparty?.kbe,
    knp: item.value?.counterparty?.knp,
    accountants: item.value?.counterparty?.accountants,
    bin: item.value?.counterparty?.bin
  })
  return arr
})
const item = computed(() => store.getters['orders/item'])
const knpOptions = computed(() => store.getters['orders/getKnp'])
const kbeOptions = computed(() => store.getters['orders/getKbe'])
const optionsPayType = computed(() => store.getters['orders/optionsPayType'])
const optionsAccounts = computed(() => {
  return formData?.counterparty?.accountants?.map((item) => {
    return {
      ...item,
      text: `${item.iban} (${item.bank_name})`,
      value: item.id
    }
  })
})

// Watcher
watch(
  () => formData.counterparty,
  () => {
    formData.counterparty_name = formData.counterparty?.name || formData.counterparty_name
  }
)
watch(isLoad, (val) => disableAllBtns('.modal', val))
watch(
  () => formData.counterparty,
  () => {
    formData.counterparty_name = formData.counterparty?.name || formData.counterparty_name
    if (!formData?.counterparty?.accountants?.filter((el) => el.id == formData.counterparty_account?.id)?.length) {
      formData.counterparty_account = {}
      formData.counterparty_bank_name = ''
      formData.counterparty_bik = ''
    }
  }
)
watch(formData, () => {
  const counterparty = formData.counterparty
  if (counterparty && Object.keys(counterparty).length) {
    const item = formData?.counterparty?.accountants?.find((i) => i.id === formData?.counterparty_account?.id)
    if (item) {
      formData.counterparty_bank_name = item.bank_name
      formData.counterparty_bik = item.bik
    }
  }
})
watch(isNewCounterparty, () => {
  if (isNewCounterparty.value) {
    isNewAccount.value = true
  }
})
watch(
  () => formData.type,
  () => {
    if (formData.type.value === 'gpc_agreement') {
      store.dispatch('GET_COUNTERPARTIES', 'gpx')
    } else {
      store.dispatch('GET_COUNTERPARTIES')
    }
    if (formData.type.value === 'holding_loans') {
      const id = window.location.href.includes('localhost') || window.location.href.includes('test') ? 50 : 25
      formData.project = optionsProjects.value.find((el) => el.id === id)
    }
  }
)

// Created
getItem()
store.dispatch('GET_USERS')
store.dispatch('GET_BANKS')
store.dispatch('GET_PROJECTS')
store.dispatch('GET_COUNTERPARTIES')
store.dispatch('orders/GET_KNP')
store.dispatch('orders/GET_KBE')

// Router before leave
onBeforeRouteLeave((to, from, next) => {
  if (isNoData.value && !leaveRoute.value) {
    isNoSaved.value = true
    leaveRoute.value = to.fullPath
  } else {
    next()
  }
})

// Methods
function closeAction() {
  router.replace('/orders')
}

function submitAction(type = false) {
  isNoSaved.value = false
  if (!type && isInvalidForm('form-add')) return (leaveRoute.value = '')
  isLoad.value = true
  const formDataFinal = new FormData()

  type === 'save' && formDataFinal.append('status_id', 10)
  formDataFinal.append('type', formData.type.value)
  formData?.company?.id && formDataFinal.append('company_id', formData.company.id || '')
  formData?.currency && formDataFinal.append('currency', formData.currency || '')
  formData?.pay_for && formDataFinal.append('pay_for', formData.pay_for || '')
  formData.payment_ground_type &&
    formDataFinal.append('payment_ground_type', formData?.payment_ground_type?.value || '')
  formData.payment_ground_purpose &&
    formDataFinal.append('payment_ground_purpose', formData?.payment_ground_purpose || '')
  formData.payment_ground_number && formDataFinal.append('payment_ground_number', formData?.payment_ground_number || '')
  formData.payment_ground_date && formDataFinal.append('payment_ground_date', formData?.payment_ground_date || '')

  if (['counterparty', 'gpc_agreement', 'holding_loans', 'regular_payments'].includes(formData.type.value)) {
    if (!isNewCounterparty.value) {
      formData.counterparty.id && formDataFinal.append('counterparty_id', formData.counterparty.id || '')
    } else {
      formData.counterparty_bin && formDataFinal.append('counterparty_bin', formData.counterparty_bin || '')
      formData.counterparty_company_name &&
        formDataFinal.append('counterparty_company_name', formData.counterparty_company_name || '')
    }
    if (!isNewAccount.value) {
      formData.counterparty_account.id &&
        formDataFinal.append('counterparty_account_id', Number(formData.counterparty_account.id || ''))
    } else {
      formData.counterparty_iban && formDataFinal.append('counterparty_iban', formData.counterparty_iban || '')
      formData.counterparty_bik && formDataFinal.append('counterparty_bik', formData.counterparty_bik || '')
      formData.counterparty_bank_id &&
        formDataFinal.append('counterparty_bank_id', formData.counterparty_bank_id?.id || '')
    }
    formData.counterparty_kbe && formDataFinal.append('counterparty_kbe_id', formData.counterparty_kbe.id || '')
    formData.counterparty_knp && formDataFinal.append('counterparty_knp_id', formData.counterparty_knp.id)
  }

  if (formData?.contract_number?.value === 'none') {
    formDataFinal.append('contract_exists', 0)
  } else {
    formDataFinal.append('contract_exists', 1)
    if (formData?.contract_number?.value === 'custom') {
      formDataFinal.append('new_contract_number', formData.new_contract_number || '')
      formDataFinal.append('new_contract_date', formData.new_contract_date || '')
    } else {
      formDataFinal.append('contract_number', formData.contract_number?.value || '')
    }
  }

  if (typeof Number(formData?.cost) === 'number' && formData?.cost) {
    formDataFinal.append('cost', formData.cost)
  }

  if (formData.commentary) {
    const comments = formData.commentary.split('<p>&nbsp;</p>').join('')
    formDataFinal.append('commentary', comments || '')
  }

  if (formData.documents?.length) {
    for (let i = 0; i < formData.documents.length; i++) {
      const file = formData.documents[i]
      if (!file?.path) {
        formDataFinal.append('documents[]', file?.file)
      }
    }
  }

  if (copyDocs.value.length) {
    for (let i = 0; i < copyDocs.value.length; i++) {
      const copy = copyDocs.value[i]
      const index = formData?.documents?.findIndex((el) => el.id === copy)
      if (index === -1) formDataFinal.append('document_ids[]', copy)
    }
  }

  const action = type ? orders.updateDraft : orders.update

  action(route.params.id, formDataFinal)
    .then(async (res) => {
      store.commit('orders/UPDATE_ROW', res.data.data)
      if (leaveRoute.value) {
        router.replace(leaveRoute.value)
      } else {
        leaveRoute.value = '/orders'
        await nextTick()
        // closeAction()
      }
      const message = type
        ? 'Заявка успешно сохранена в черновик!'
        : 'Заявка успешно создана, ожидайте пока Финансисты возьмут ее в работу.'

      $busEmit('setToast', {
        type: 'green',
        icon: 'check-circle',
        message
      })
    })
    .finally(() => {
      isLoad.value = false
    })
}

async function getItem() {
  isLoad.value = true
  try {
    await store.dispatch('GET_COUNTERPARTIES')
    await store.dispatch('GET_PROJECTS')
    await store.dispatch('orders/GET_KNP')
    await store.dispatch('orders/GET_KBE')
    await store.dispatch('orders/GET_ITEM', route.params.id)
    formData.type = optionsPayType.value.find((i) => i.value == item.value.type)
    formData.company = optionsProjects.value.find((i) => i.id == item.value.company.id)
    formData.pay_for = item.value?.pay_for
    formData.cost = Number(item.value?.cost)
    formData.currency = item.value?.currency
    formData.commentary = item.value?.commentary
    optionsCounterparties.value.push(item.value?.counterparty)
    formData.counterparty = optionsCounterparties?.value?.find((i) => i.id == item?.value?.counterparty?.id) || {}
    formData.counterparty_kbe = item.value?.kbe
    formData.counterparty_knp = item.value?.knp
    formData.counterparty_account = optionsAccounts?.value?.find((i) => i.id == item?.value?.counterparty_account_id)
    formData.payment_ground_type = paymentTypeOptions?.find((i) => i.value == item?.value?.payment_ground_type)
    formData.payment_ground_number = item.value?.payment_ground_number
    formData.payment_ground_date = item.value?.payment_ground_date
    formData.documents = item.value?.documents
    copyDocs.value = cloneDeep(item.value?.documents.map((el) => el?.id))
    if (item.value?.contract_exists) {
      formData.contract_exists = true
      formData.contract_number = {
        value: item.value?.contract?.id,
        id: item.value?.contract?.id,
        text: `${item.value?.contract?.number} ${item.value?.contract?.date_to}`
      }
    } else {
      formData.contract_exists = false
      formData.contract_number = contractExistsOptions.value.find((i) => i.value == 'none')
    }
  } finally {
    isLoad.value = false
    store.commit('orders/UPDATE_ROW', item)
  }
}

async function searchCounterparties(searchText) {
  const type = formData.type.value === 'gpc_agreement' && 'gpx'
  if (searchText.length >= 3) {
    isCounterpartyLoad.value = true
    await store.dispatch('GET_COUNTERPARTIES_SEARCH', { searchText, type })
    isCounterpartyLoad.value = false
  } else if (!searchText) {
    isCounterpartyLoad.value = true
    await store.dispatch('GET_COUNTERPARTIES', type)
    isCounterpartyLoad.value = false
  }
}
</script>

<style lang="scss" scoped>
.form-add {
  min-height: calc(100vh - 300px);
  width: 800px;
  position: relative;

  &__section {
    // border-bottom: var(--b1);
    padding: 20px;

    &:last-child {
      border: 0;
    }

    .title {
      // color: var(--primary);
    }

    &-box {
      &-btn {
        &.active {
          background-color: var(--grey);
          border-radius: var(--br) var(--br) 0 0;
        }
      }
      &-body {
        background-color: var(--box);
        border-radius: 0 0 var(--br) var(--br);
        border: var(--b1);
        border-top: 0;
        padding: 20px;
      }
    }
  }
}
</style>
